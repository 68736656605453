import React from 'react';
import moment from 'moment';
import { Box, Button, ToolTip, Typography } from '@esgian/esgianui';
import {
  getMainPageSlice,
  setCustomUTC,
  setTimeZone
} from '../../store/features/filters/MainPageSlice/MainPageSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DATE_FORMAT, TIME_ZONE } from '../../constants';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../hooks';

interface Well {
  rigname: string;
  wellname: string;
  starttime: string;
  endtime: string;
  reportdate: string;
  isSelected: boolean;
}

interface WellsButtonProps {
  wells: Well[];
  startDate: string;
  endDate: string;
  selectedRange: [string, string];
  onWellClick: (
    period: { startDate: string; endDate: string },
    type?: string,
    timeZone?: string,
    name?: string
  ) => void;
}

const WellsButton: React.FC<WellsButtonProps> = ({
  wells,
  startDate,
  endDate,
  selectedRange,
  onWellClick
}) => {
  const dispatch = useDispatch();
  const { rigUTC, timeZone, customUTC } = useSelector(getMainPageSlice);
  const { theme } = useTheme();

  const totalTimelineDays = moment(endDate).diff(moment(startDate), 'days');

  const normalizedSelectedRange = [
    moment(selectedRange[0]).format('YYYY-MM-DD'),
    moment(selectedRange[1]).format('YYYY-MM-DD')
  ];

  const positionedWells = wells.map((well) => {
    const wellStart = moment(well.starttime).format('YYYY-MM-DD');
    const wellEnd = moment(well.endtime).format('YYYY-MM-DD');

    const clampedStart = moment.max(moment(wellStart), moment(startDate));
    const clampedEnd = moment.min(moment(wellEnd), moment(endDate));

    const startPercentage =
      (clampedStart.diff(moment(startDate), 'days') / totalTimelineDays) * 100;
    const endPercentage = (clampedEnd.diff(moment(startDate), 'days') / totalTimelineDays) * 100;

    return {
      ...well,
      isVisible: moment(wellStart).isBefore(endDate) && moment(wellEnd).isAfter(startDate),
      isSelected:
        normalizedSelectedRange[0] === wellStart && normalizedSelectedRange[1] === wellEnd,
      position: {
        left: `${Math.max(0, startPercentage)}%`,
        width: `${Math.max(0, endPercentage - startPercentage)}%`
      }
    };
  });

  const isTimeZoneMatch = (well: Well) => {
    const dateParts = well.starttime.split(/([+-]\d{2}:\d{2})$/);
    const offset = dateParts[1];
    const possibleTimezones = moment.tz.names().filter((tz) => {
      return moment.tz(well.starttime, tz).format('Z') === offset;
    });
    const currentTimeZone = timeZone !== TIME_ZONE.RIGS_LOCATION ? customUTC?.value : rigUTC;
    const currentUtcOffset = moment.tz(currentTimeZone).utcOffset() / 60;
    const selectedUtcOffset = moment.tz(possibleTimezones[0]).utcOffset() / 60;
    if (currentUtcOffset === selectedUtcOffset) {
      return {
        possibleTimezones,
        match: true,
        offset
      };
    }
    return {
      possibleTimezones,
      match: false,
      offset
    };
  };

  const handleWellClick = (well: Well) => {
    const { possibleTimezones, match, offset } = isTimeZoneMatch(well);
    if (!match) {
      toast.warning(`Timezone changed to UTC ${offset}`);
    }
    const timezoneName = possibleTimezones.length
      ? possibleTimezones[0]
      : 'No matching timezone found';
    dispatch(setTimeZone('CustomTimeZone'));
    dispatch(setCustomUTC({ value: timezoneName, label: `${timezoneName} (UTC${offset})` }));
    onWellClick(
      {
        startDate: well.starttime,
        endDate: well.endtime
      },
      'well',
      `${well?.wellname}: ${moment(well.starttime, DATE_FORMAT).format(DATE_FORMAT)} - ${moment(
        well.endtime,
        DATE_FORMAT
      ).format(DATE_FORMAT)}`
    );
  };

  return (
    <Box sx={{ position: 'relative', height: '18px', marginBottom: '4px', overflow: 'hidden' }}>
      {positionedWells.map((well, i) =>
        well.isVisible ? (
          <ToolTip
            key={i}
            arrow
            placement="top"
            title={
              <Box sx={{ width: '268px' }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 0.5 }}>
                  {well.wellname}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0.5 }}>
                  <Typography variant="body2">Well ID:</Typography>
                  <Typography variant="body2">{well.wellname}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0.5 }}>
                  <Typography variant="body2">Start Date:</Typography>
                  <Typography variant="body2">
                    {moment(well.starttime).format(DATE_FORMAT)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2">End Date:</Typography>
                  <Typography variant="body2">
                    {moment(well.endtime).format(DATE_FORMAT)}
                  </Typography>
                </Box>
              </Box>
            }>
            <Button
              onClick={() => handleWellClick(well)}
              color={well.isSelected ? 'primary' : 'secondary'}
              sx={{
                mr: 1,
                background: well.isSelected
                  ? '#017CFF'
                  : theme.mode === 'dark'
                  ? 'rgba(196, 196, 196, 0.25)'
                  : 'rgba(123, 123, 123, 0.25)',
                borderRight: '0.5px solid',
                borderLeft: '0.5px solid',
                borderColor: theme.mode === 'dark' ? '#000' : '#fff',
                position: 'absolute',
                left: well.position.left,
                width: well.position.width,
                padding: 0,
                borderRadius: 0,
                height: 18,
                fontSize: 10,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                minWidth: 'auto',
                zIndex: well.isSelected ? 2 : 1,
                py: well.isSelected ? '1px' : 0,
                '&:hover': {
                  zIndex: 3,
                  p: 0
                }
              }}>
              {well.wellname}
            </Button>
          </ToolTip>
        ) : null
      )}
    </Box>
  );
};

export default WellsButton;
